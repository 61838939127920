// component pagination
.pagination {
  ul.pagination-component {
    display: flex;
    font-size: 20px;
    justify-content: center;
    gap: 20px;
    margin: 0 auto;
    width: 70%;

    li {
      cursor: pointer;
    }
    li.previous,
    li.next {
      &:hover {
        transition: all 0.1s;
      }
    }
    li.previous:hover {
      transform: translateX(-5px);
    }
    li.next:hover {
      transform: translateX(5px);
    }
    li.previous.disabled,
    li.next.disabled {
      color: gray;
      transform: none;
      cursor: auto;
    }
    li.active {
      border: 1px solid black;
      padding: 0px 5px;
    }
  }
}
// component show caase
.show-case {
  .image-set {
    width: 100%;
    position: relative;

    img {
      width: 100%;
      height: 316px;
      object-fit: cover;
    }
    img:nth-child(2) {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      opacity: 0;
      &:hover {
        opacity: 1;
      }
      transition: opacity 0.5s;
    }
  }
  .title,
  .location {
    font-size: 20px;
    letter-spacing: 0.5px;
    font-weight: bold;
    margin-top: 5px;

    a {
      text-decoration: none;
      color: black;
    }
  }

  .tags {
    margin-top: 14px;
    font-size: 18px;
    li {
      display: inline-block;
      &:before {
        content: '|';
        display: inline-flex;
        width: 20px;
        justify-content: center;
      }
      &:first-child:before {
        display: none;
      }
    }
  }
}

// page level
// mobile first

.cases {
  padding: 0px 25px;
  .mobile {
    display: block;
  }
  .desktop {
    display: none;
  }
  h1 {
    color: black;
    font-size: 28px;
    font-weight: 300;
    text-align: center;
    margin: 39px 0px 39px 0;
  }
  .show-case {
    margin-bottom: 30px;
  }

  .pagination {
    margin: 30px 0px 40px;
  }
}

// desktop
@media only screen and (min-width: 980px) {
  .cases {
    padding: 0px;

    h1 {
      color: black;
      font-size: 38px;
      font-weight: 300;
      text-align: center;
      margin: 70px 0px;
    }

    .mobile {
      display: none;
    }
    .desktop {
      display: block;
      max-width: 1460px;
      margin: 0 auto;
    }
    > ul {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      max-width: 1100px;
      margin: 85px auto 0px;

      .show-case {
        width: 462px;
        margin-bottom: 90px;

        .title {
          margin-top: 28px;
        }

        .tags {
          margin-top: 25px;
          font-size: 16px;
        }
      }
    }
    .pagination {
      margin-bottom: 70px;
    }
  }
}
