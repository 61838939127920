.modal-open {
  overflow: hidden;
}

// mobile first
.index-page {
  .mobile {
    display: block;
  }
  .desktop {
    display: none;
  }
  .hero {
    width: 100%;
    position: relative;
    background-color: $grey80;

    img {
      width: 100%;
      object-fit: cover;
      min-height: 300px;
      height: calc(100vh - 80px);
    }

    .content {
      font-size: 17px;
      height: 100px;
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: center;
      gap: 30px;
      letter-spacing: 6px;
      font-weight: bold;
      line-height: 32px;
      position: absolute;
      bottom: 0px;
      color: white;

      > div {
        max-width: 140px;
        padding: 0px;
      }
      .number {
        font-size: 40px;
        text-align: center;
        font-weight: 300;
        letter-spacing: 1px;
      }
      .sen2 > div:nth-child(2) {
        margin-top: 3px;
        letter-spacing: 6px;
      }
    }
  }

  .video-modal-wrapper {
    display: flex;
    justify-content: center;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    overflow-x: hidden;
    overflow-y: scroll;
    overflow-scrolling: touch;
    -webkit-overflow-scrolling: touch;
    z-index: 20;
    max-height: 100%;
    background: rgba(0, 0, 0, 0.7);

    .video-content {
      text-align: center;
      width: 90%;
      max-width: 1200px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      iframe {
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        height: 100%;
        transform: translate(0%, -50%);
      }
    }
  }

  .video-block-wrapper {
    background-color: #05141f;
    position: relative;

    .modal-close-button {
      cursor: pointer;
      position: fixed;
      top: 0;
      right: 0;
      z-index: 99;
      padding: 6px 15px;
      font-size: 24px;
      color: white;
      opacity: 0.8;
      transition: opacity 0.2s;
    }

    .video-block {
      position: relative;
      margin: auto;
      padding: 80px 0px;
      position: relative;
      overflow: hidden;
      max-width: 1052px;

      .video-cover {
        width: 100%;

        .video-background {
          height: 307px;
        }
      }
      .play {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
        width: 80px;
        cursor: pointer;

        &:hover {
          width: 100px;
          transition: all 0.4s;
        }
      }
    }
  }

  .report {
    padding: 0px 12px;

    > h1 {
      font-size: 28px;
      font-weight: 300;
      text-align: center;
      margin: 20px 0px;
      width: 100%;
    }

    .report-block {
      .story {
        width: 95%;
        margin-top: 20px;
        display: flex;
        justify-content: center;
        img {
          width: 100%;
          object-fit: contain;
        }
      }
      .youtube-wrapper {
        height: 200px;
        box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.6);
      }
      .title {
        font-size: 22px;
        margin-top: 20px;
        text-align: center;
        line-height: 32px;
      }
      .description {
        margin-top: 20px;
        padding: 0px 5px;
        font-size: 18px;
        letter-spacing: 2px;
        text-align: center;
        line-height: 24px;
      }
    }
  }

  .service {
    background: url('./images/service-background-mobile.jpg');
    background-size: cover;
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: white;
    margin-top: 15px;
    > h1 {
      font-size: 28px;
      font-weight: 300;
      text-align: center;
      // margin: 20px 0px;
      margin: 0px;
      width: 100%;
    }
    > p {
      padding: 0px 8px;
      font-size: 18px;
      line-height: 28px;
      text-align: center;
    }
  }

  .design {
    .img-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 45px;
      img {
        border-radius: 235px;
        width: 235px;
        height: 235px;
      }
    }
    .title {
      display: flex;
      justify-content: center;
      font-size: 26px;
      margin-top: 55px;
    }
    button {
      margin: 0 auto;
      margin-top: 17px;
      display: block;
      width: 140px;
      background: none;
      border: 1px solid black;
      height: 50px;

      a {
        color: black;
        text-decoration: none;
      }
    }
  }

  .others {
    margin-top: 80px;
    .feedbacks {
      background: url('./images/feedback-background.webp');
      height: 148px;
      background-position: bottom right;

      h1 {
        font-size: 26px;
        font-weight: 300;
        text-align: center;
        width: 100%;
        color: white;
        padding: 50px 10px 0px;
        text-align: left;
        line-height: 36px;
      }
    }

    .others-block {
      display: flex;
      flex-direction: column;
      margin-top: 40px;

      h3 {
        font-weight: 400;
      }
      p {
        line-height: 25px;
        font-size: 17px;
      }
      p.name {
        align-self: flex-end;
        font-size: 19px;
        font-weight: bold;
        margin-bottom: 0px;
      }
      button {
        margin-top: 17px;
        display: block;
        width: 140px;
        background: none;
        border: 1px solid black;
        height: 50px;

        a {
          color: black;
          text-decoration: none;
        }
      }
    }

    .container {
      max-width: 1100px;
      margin: 0 auto;
    }
    .flex {
      display: flex;
      flex-direction: column;
      padding: 0px 20px;
    }
  }

  .foot-print {
    margin-top: 60px;
    > h1 {
      font-size: 28px;
      font-weight: 300;
      text-align: center;
      margin: 30px 0px;
      width: 100%;
    }
    .img-wrapper {
      height: 300px;
      overflow: hidden;
      img {
        height: 300px;
        width: 100%;
        object-fit: contain;
      }
    }
  }
}

// desktop
@media only screen and (min-width: 1260px) {
  .index-page {
    .desktop {
      display: block;
    }
    .mobile {
      display: none;
    }

    .hero {
      img {
        width: 100%;
        object-fit: cover;
        max-height: none;
        height: calc(100vh - 109px);
      }

      .content {
        height: 146px;
        justify-content: flex-end;
        font-size: 30px;

        > div {
          max-width: inherit;
          margin-top: 30px;
        }

        .sen2 {
          display: flex;
          gap: 45px;

          > .more {
            font-size: 20px;
            margin-top: 5px;
            color: white;
            text-decoration: none;
          }
        }
        .number {
          font-size: 92px;
          margin-top: -10px;
        }
      }
    }

    .video-block-wrapper {
      padding: 120px 0px;
    }

    .report {
      > h1 {
        font-size: 40px;
        margin: 90px 0px;
      }
      .report-block-wrapper {
        display: flex;
        max-width: 1280px;
        margin: 0 auto;
        gap: 160px;
        margin-bottom: 90px;

        .report-block {
          .title {
            margin-bottom: 40px;
          }
          .description {
            width: 480px;
            margin: 0 auto;
          }
        }

        .report-block:first-child {
          margin-left: 36px;
          .title {
            margin-top: 80px;
          }
        }
        .report-block:nth-child(2) {
          margin-top: -15px;
          .title {
            margin-top: 50px;
          }
        }

        .youtube-wrapper {
          width: 566px;
          height: 318px;
        }
        .story {
          margin-top: 0px;
          img {
            width: 475px;
          }
        }
      }
    }

    .service {
      background: url('./images/service-background-mobile-desktop.webp');
      height: 480px;
      background-size: cover;
      > h1 {
        font-size: 40px;
      }
      > p {
        max-width: 949px;
        margin-top: 100px;
      }
    }

    .design {
      ul {
        display: flex;
        justify-content: center;
        gap: 220px;
        margin: 60px 0px 100px 0px;

        .img-wrapper img {
          width: 220px;
          height: 220px;
          border-radius: 220px;
        }
      }
      button {
        margin-top: 70px;
      }
    }

    .others {
      .feedbacks {
        height: 670px;
        background-size: cover;
        h1 {
          text-align: center;
          font-size: 34px;
          padding-top: 140px;
        }
      }
      > .container {
        margin-top: -460px;
        max-width: inherit;
        .flex {
          flex-direction: row;
          justify-content: center;
          gap: 60px;
        }
        .others-block {
          background: white;
          width: 490px;
          height: 355px;
          padding: 40px 90px 20px;
          position: relative;

          button {
            margin: 0 auto;
            height: 30px;
            width: 120px;
            position: absolute;
            bottom: 30px;
            transform: translate(-50%, 0px);
            left: 50%;
          }
          p {
            font-size: 16px;
          }
          .name {
            position: absolute;
            right: 0px;
            bottom: 120px;
            right: 90px;
            font-size: 18px;
          }
        }
      }
    }

    .foot-print {
      margin-top: 140px;
      > h1 {
        font-size: 40px;
        margin: 60px 0px;
      }
      .img-wrapper {
        height: 540px;
        img {
          height: 540px;
          width: 100%;
          object-fit: cover;
          object-position: 50% 50%;
          transform: scale(1);
        }
      }
    }
  }
}
