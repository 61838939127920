.about-page {
  font-smooth: auto;
  -webkit-font-smoothing: auto;
  font-family: adobe-song-std, serif;

  .first-section {
    margin-bottom: 48px;
    h1 {
      color: rgba(0, 0, 0, 1);
      font-size: 28px;
      font-weight: 300;
      text-align: center;
      margin: 39px 0px 39px 0;
    }
    .image-wrapper {
      padding: 0px 10px;
      img {
        width: 100%;
      }
      img.mobile {
        display: block;
      }
      img.desktop {
        display: none;
      }
    }
    .title,
    p {
      display: block;
      text-align: center;
      font-size: 16px;
      margin: 0px;
      line-height: 30px;
      padding: 0px 10px;
      overflow-wrap: break-word;
    }
  }

  .second-section {
    .wrapper {
      background-color: rgb(240, 240, 240);
      img {
        width: 100%;
      }
    }
    .text {
      background-color: rgb(240, 240, 240);
      padding: 0px 20px;
      h2,
      p {
        font-weight: normal;
        text-align: center;
      }
      h2 {
        line-height: 28px;
        margin: 45px 0px 14px 0px;
        font-size: 24px;
      }
      p {
        font-size: 16px;
        line-height: 28px;
        margin-bottom: 45px;
      }
    }
  }

  .third-section {
    padding: 0px 20px;
    margin-bottom: 120px;
    h2,
    p {
      font-size: 16px;
      font-weight: normal;
      text-align: center;
    }
    h2 {
      line-height: 34px;
      margin: 22px 0px 16px 0px;
      font-size: 21px;
    }
    p {
      font-size: 16px;
      line-height: 22px;
      margin: 0px 0px 20px 0px;
    }
  }
  .fourth-section {
    background: url('./section4-background.jpg');
    background-size: cover;
    height: 330px;
    padding: 0px 20px;

    h2 {
      text-align: center;
      line-height: 36px;
      font-size: 22px;
      font-weight: normal;
      color: white;
      padding-top: 77px;
    }

    a {
      background-color: black;
      height: 55px;
      width: 116px;
      text-align: center;
      display: block;
      margin: 0 auto;
      text-decoration: none;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
    }
  }
}

@media only screen and (min-width: 968px) {
  .about-page {
    .first-section {
      margin-top: 60px;
      margin-bottom: 120px;
      display: grid;
      grid-template-columns: 1fr 460px 460px 1fr;
      grid-template-rows: 180px 1fr;
      grid-template-areas:
        '. header image'
        '. text image';
      grid-column-gap: 60px;

      h1 {
        grid-area: header;
        justify-self: start;
        font-size: 40px;
        margin: 99px 0px 30px 0px;
      }
      .image-wrapper {
        grid-area: image;
        justify-self: start;
        padding: 0px;
        box-shadow: 20px 20px 0px 0px #000000;

        img.desktop {
          display: block;
        }
        img.mobile {
          display: none;
        }
      }
      .text {
        grid-area: text;
        max-width: 460px;
        justify-self: end;

        .title,
        p {
          text-align: left;
          padding: 0px;
          font-size: 15px;
        }
      }
    }
    // second section
    .second-section {
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-template-rows: 360px 360px;
      grid-template-areas:
        'img1 area2 img3 area4'
        'area1 img2 area3 img4';
      .wrapper {
        img {
          height: 100%;
        }
      }
      .area1 {
        grid-area: area1;
      }
      .img2 {
        grid-area: img2;
      }
      .area3 {
        grid-area: area3;
      }
      .img4 {
        grid-area: img4;
      }
      .text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 0 auto;
        width: 100%;
        padding: 22%;
        h2 {
          font-size: 22px;
        }
        p,
        h2 {
          width: 100%;
          text-align: center;
        }
      }
    }

    // third section
    .third-section {
      margin-bottom: 66px;
      h2,
      p {
        line-height: 30px;
      }
      h2 {
        margin: 122px 0px 40px 0px;
        font-size: 40px;
      }
      p {
        font-size: 22px;
        margin-bottom: 32px;
      }
    }

    // fourth section
    .fourth-section {
      background: url('./section4-background-desktop.webp');
      background-size: cover;
      background-position: 50% 50%;
      position: relative;
      height: 680px;
      padding: 0px 20px;
      display: grid;
      grid-template-rows: min-content 1fr;
      grid-template-columns: 100%;

      h2 {
        position: relative;
        margin: 252px 0px 57px calc((100% - 980px) * 0.5);
        left: 20px;
        grid-area: 1 / 1 / 2 / 2;
        justify-self: start;
        align-self: start;
        padding: 0px;
        font-size: 20px;
      }
      a {
        position: relative;
        margin: 0px 0px 10px calc((100% - 980px) * 0.5);
        left: 20px;
        grid-area: 2 / 1 / 3 / 2;
        justify-self: start;
        align-self: start;
        font-size: 15px;
        width: 100px;
        height: 45px;
      }
    }
  }
}
