// $hamburger-padding-x           : 15px;
// $hamburger-padding-y           : 15px;
// $hamburger-layer-width         : 22px;
// $hamburger-layer-height        : 4px;
// $hamburger-layer-spacing       : 6px;

@import '~hamburgers/dist/hamburgers.min.css';

.hamburger--slider {
  padding: 0px;
}

.hamburger-box {
  width: 24px;
  height: 20px;
}

.hamburger-inner,
.hamburger-inner:after,
.hamburger-inner:before {
  width: 24px;
  height: 2px;
}

header {
  height: 80px;
  position: relative;
  z-index: 10;

  .header-content {
    height: 80px;
    background: rgba(255, 255, 255, 0.6);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.7);
    position: fixed;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  label {
    position: absolute;
    top: 30px;
    right: 25px;
    z-index: 1001;
  }
  .content a {
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
  }
  .content ul li a {
    height: 65px;
  }

  .logo {
    width: 178px;
    height: 40px;
  }
}

/*
 Mobile first
*/

.main-menu {
  position: fixed;
  display: none;
  width: 100%;
  top: 0;
  height: 100%;
  overflow-y: auto;
  overflow-x: visible;
  z-index: 999;

  ul {
    list-style: none;
    margin: 0;
    padding-top: 100px;
    min-height: 100%;
    background-color: white;
  }
  a {
    display: block;
    padding: 5px;
    color: black;
    text-decoration: none;
    font-weight: bold;
    font-size: 22px;
  }

  a:focus {
    color: rgb(170, 188, 191);
  }
}

#toggler {
  display: none;
}

#toggler:checked + .main-menu {
  display: block;
  left: 0;
  outline: none;
}

#toggler:checked + .main-menu ul {
  position: relative;
  z-index: 1000;
}

#toggler:checked ~ .backdrop {
  position: fixed;
  display: block;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 998;
  background: white;
  cursor: default;
}

/*
 desktop
*/
@media (min-width: 1260px) {
  header {
    position: relative;
    height: 109px;

    .header-content {
      background: rgba(255, 255, 255, 0.6);
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.7);
      position: fixed;
      width: 100%;
      height: 109px;
      flex-flow: row nowrap;
      align-items: center;

      .content {
        height: 109px;
        width: 1560px;
        display: flex;
        justify-content: center;
        justify-content: space-between;

        a {
          height: 109px;
        }
      }
    }

    .logo {
      height: 55px;
      width: auto;
      margin-top: 8px;
      position: absolute;
      left: 5%;
    }
  }
  .main-menu {
    width: auto;
  }
  .menu-toggle,
  .main-menu .menu-close {
    display: none;
  }

  .main-menu {
    position: static;
    display: block;
    margin-right: 30px;
    ul {
      padding: 0;
      height: auto;
      width: auto;
      background: none;
      overflow: hidden;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }

  .backdrop {
    display: none;
  }

  .main-menu a {
    color: black;
    border-bottom: none;
    display: inline;
    font-size: 16px;
    padding-left: 20px;
  }
  .main-menu a:hover,
  .main-menu a:focus {
    background: none;
    color: #7e64be;
    text-decoration: none;
  }
}
