// mobile
.service-page {
  .arrow-wrapper {
    width: 15px;
    display: block;

    svg {
      width: 15px;
      height: 15px;
    }

    > p {
      display: none;
    }
  }

  h1 {
    font-size: 28px;
    font-weight: 300;
    text-align: center;
    margin: 39px 0px 39px 0;
  }
  .service-list-item {
    .image-wrapper {
      img {
        width: 100%;
      }
    }
    h2 {
      text-align: center;
      font-weight: 300;
      font-size: 30px;
      margin-bottom: 40px;
    }
    div {
      text-align: center;
      font-size: 21px;
      line-height: 40px;
      margin-bottom: 0px;
    }
    .arrow-wrapper {
      margin: 20px auto 30px;
      text-decoration: none;
      color: black;
    }
  }
  // detials sections
  .service-detail-section {
    .image-wrapper {
      img {
        width: 100%;
      }
      margin-bottom: 60px;
    }
    .service-section {
      margin-bottom: 60px;
    }
    .title {
      background-color: rgb(240, 240, 240);
      font-size: 26px;
      padding: 14px 0px 14px 20px;
    }
    .sub-title {
      display: none;
    }
    ul {
      margin: 0px;
      position: relative;

      &::before {
        content: '';
        display: block;
        height: 100%;
        border: 2px solid rgb(128, 128, 128);
        position: absolute;
        left: 36px;
        top: 28px;
      }
    }
    .service-detail {
      margin-top: 20px;
    }
    .detail-content {
      > ul::before {
        top: 26px;
      }
    }
    .detail-content:last-child {
      > ul::before {
        top: -22px;
      }
    }

    .desc {
      font-size: 24px;
      line-height: 58px;
      &:before {
        background-color: rgb(128, 128, 128);
        content: '';
        display: inline-block;
        width: 12px;
        height: 12px;
        border-radius: 20px;
        margin-right: 15px;
        margin-left: 32px;
      }
    }
  }
}

// desktop
@media only screen and (min-width: 980px) {
  .service-page {
    max-width: 979px;
    margin: 0 auto;

    h1 {
      font-size: 38px;
      margin: 60px 0px;
    }
    .service-section {
      display: flex;
      justify-content: center;
      gap: 25px;

      li {
        width: 311px;
      }
    }
    .service-list-item {
      h2 {
        font-size: 25px;
        margin: 30px 0px;
      }
      div {
        font-size: 18px;
        line-height: 32px;
        min-height: 224px;
      }
    }
    .arrow-wrapper {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;
      > p {
        display: block;
      }

      > svg {
        height: 10px;
      }
    }

    .service-detail-section {
      display: block;

      .service-section {
        display: block;
      }

      .image-wrapper {
        margin-bottom: 30px;
      }
      .service-detail {
        display: flex;
        gap: 12px;
        margin-top: 40px;

        .detail-content {
          ul::before {
            display: none;
          }

          ul {
            display: flex;
            justify-content: space-around;
            li.desc {
              margin-top: 40px;
              font-size: 20px;
              width: 30px;
              line-height: 24px;
              word-wrap: break-word;
              &::before {
                margin: 0px;
                margin-left: 4px;
                margin-bottom: 20px;
              }
            }
          }
        }
        .sub-title {
          display: block;
          font-size: 20px;
          text-align: center;
          border-bottom: 3px solid rgb(64, 64, 64);
          padding-bottom: 12px;
        }
      }
    }
  }
}
