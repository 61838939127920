.faq-page {
  // first section
  .first-section {
    .image-wrapper {
      img {
        width: 100%;
      }
      img.mobile {
        display: block;
      }
      img.desktop {
        display: none;
      }
    }
  }
  // second section
  .second-section {
    padding: 0px 10px;
    h1 {
      font-size: 24px;
      font-weight: 300;
      margin: 45px 0px 22px 0px;
    }
    ul {
      font-size: 20px;
      line-height: 30px;

      input {
        display: none;
      }
      input ~ p {
        padding: 0px;
        overflow: hidden;
        margin: 0px;
        max-height: 0px;
      }

      input:checked ~ p {
        padding: 0px 28px 30px;
        margin: 16px 0px;
        max-height: 100%;
      }

      p {
        font-size: 20px;
      }

      li {
        border-bottom: 2px solid rgba(66, 66, 66, 0.79);
        padding-bottom: 12px;
        padding-top: 30px;

        .arrow {
          border: solid rgb(36, 35, 35);
          border-width: 0 3px 3px 0;
          display: inline-block;
          padding: 6px;
          border-radius: 2px;
        }

        .right {
          transform: rotate(-45deg);
          -webkit-transform: rotate(-45deg);
        }

        .left {
          transform: rotate(135deg);
          -webkit-transform: rotate(135deg);
        }

        .up {
          transform: rotate(-135deg);
          -webkit-transform: rotate(-135deg);
        }

        .down {
          transform: rotate(45deg);
          -webkit-transform: rotate(45deg);
        }

        label {
          display: flex;
          align-items: center;
          justify-content: space-between;

          i {
            width: 14px;
            height: 14px;
            align-self: flex-start;
            margin: 2px 10px 0px;

            @extend .down;
          }
        }
        input:checked + label {
          i {
            @extend .up;
            margin-top: 10px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 968px) {
  .faq-page {
    .first-section {
      .image-wrapper {
        img {
          width: 100%;
        }
        img.mobile {
          display: none;
        }
        img.desktop {
          display: block;
        }
      }
    }
    .second-section {
      width: 980px;
      margin: 0 auto;
      padding: 0px;
      h1 {
        text-align: center;
        font-size: 40px;
        margin: 100px 0px 80px;
      }

      label {
        font-size: 22px;
      }
      ul {
        margin-bottom: 120px;
        li {
          padding: 50px 20px;
        }

        input:checked ~ p {
          padding-bottom: 0px;
        }
      }
    }
  }
}
