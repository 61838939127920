$grey: #c3c3c3;
$grey80: rgba(195, 195, 195, 0.4);
$centerWidth: 1175px;
$adobeStdR: 'Adobe Std';

html {
  scroll-behavior: smooth;
}

@font-face {
  font-family: 'Adobe Std';
  src: local('Adobe 楷体 Std R'), url('./fonts/Adobe_std.otf') format('opentype');
}

.adobeStdR {
  font-family: $adobeStdR;
}

ul {
  padding: 0px;
}

li {
  list-style: none;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  // -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smooth: auto;
  -webkit-font-smoothing: auto;
  font-family: adobe-song-std, serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.center-section {
  width: $centerWidth;
}

.clearboth {
  clear: both;
}

.grid {
  display: grid;
}

@import './components/Navbar/Navbar.scss';
@import './pages/index/index.scss';
@import './pages/contact/contact.scss';
@import './pages/about/about.scss';
@import './pages/faq/faq.scss';
@import './pages/customers/customers.scss';
@import './pages/services/Services.scss';
@import './pages/cases/cases.scss';
@import './pages/cases/case-detail.scss';
