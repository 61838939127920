// mobile
.contact-page {
  padding: 0px 20px;

  .title {
    font-size: 28px;
    font-weight: 300;
    text-align: center;
    margin: 39px 0px 39px 0;
  }
  .center-block {
    max-width: 1030px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;

    .map {
      margin-bottom: 30px;
      iframe {
        max-width: 100%;
        // width: 100%;
      }
    }

    .contact {
      .select {
        position: relative;
        &::after {
          content: '';
          display: inline-block;
          width: 0;
          height: 0;
          border-left: 6px solid transparent;
          border-right: 6px solid transparent;
          border-top: 6px solid black;
          position: absolute;
          right: 16px;
          top: 20px;
        }
      }

      input,
      select {
        width: 100%;
        display: block;
        height: 42px;
        margin-bottom: 30px;
        letter-spacing: 3px;
        padding-left: 15px;
        background-color: rgb(247, 247, 247);
        color: rgb(104, 104, 104);
        border: none;
      }
      select {
        appearance: none;
      }

      button {
        float: right;
        background: rgb(202, 202, 202);
        color: rgb(104, 104, 104);
        height: 42px;
        width: 200px;
        border: none;
        border-radius: 42px;
        margin-bottom: 100px;
      }
      .black {
        color: black;
      }
      input.error {
        border: 1px solid red;
      }
    }
  }
}

// desktop
@media only screen and (min-width: 480px) {
  .contact-page {
    background-size: cover;
    background-repeat: no-repeat;

    .title {
      font-size: 38px;
      max-width: 100%;
      margin: 60px 0px;
      padding: 0px;
      display: flex;
      justify-content: center;
    }

    .center-block {
      flex-direction: row;

      .map {
        width: 94%;
        margin-right: 4%;
      }
      .contact {
        width: 94%;
        margin-left: 3%;
        margin-right: 1%;
      }
    }
  }
}
