// update carousel lib style
.slider-wrapper {
  > ul.slider > li > div {
    height: 100%;
    > img {
      object-fit: cover;
      height: 100%;
    }
  }
}

.case-detail {
  padding-bottom: 20px;
  // hide thumbs for mobile
  .carousel.carousel-slider + .carousel {
    display: none;
  }
  h1 {
    color: black;
    font-size: 28px;
    font-weight: 300;
    text-align: center;
    margin: 39px 0px 39px 0;
  }

  .detail-section {
    padding: 0px 10px;
    font-size: 23px;
    line-height: 28px;

    .title {
      font-weight: bold;
      margin-top: 10px;
      letter-spacing: 1px;
    }
    .description {
      font-size: 21px;
    }
    .line {
      border: 2px solid rgb(64, 64, 64);
      margin: 15px 0px;
      width: 100%;
    }
    .learn-more {
      font-weight: 400;
      color: black;
      margin: 40px 0px;
      display: block;
    }
  }
}

// desktop
@media only screen and (min-width: 980px) {
  .case-detail {
    // hide thumbs for mobile
    .carousel.carousel-slider + .carousel {
      display: block;

      > .thumbs-wrapper {
        margin: 20px 100px 0px;
      }
    }
    .carousel.carousel-slider {
      width: 100% !important;
    }

    h1 {
      color: black;
      font-size: 38px;
      font-weight: 300;
      text-align: center;
      margin: 70px 0px;
    }

    .content {
      max-width: 1260px;
      margin: 0 auto;
      display: flex;
      > .Carousel-component {
        max-width: 756px;

        .thumbs-wrapper {
          .thumb {
            width: 80px;
            height: 52px;
            img {
              width: 80px;
              height: 52px;
              object-fit: cover;
            }
          }
        }
      }
      > .detail-section {
        width: 40%;
      }
      img {
        max-height: 567px;
      }
    }

    .detail-section {
      margin-top: 80px;
      padding: 0px 60px;
    }
  }
}
