// mobile first
.user-like-us {
  .scroll-block {
    height: 520px;
    background: rgb(240, 240, 240);
  }
  h1 {
    font-size: 22px;
    padding: 10px 20px;
    text-align: center;
    font-weight: 400;
    line-height: 32px;
    margin: 0px;
  }
  .scroll {
    text-align: center;
    font-size: 22px;
    font-style: italic;
    margin-top: 10px;
  }
  .arrow {
    transform: rotate(270deg);
    height: 7px;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
    overflow: hidden;
  }
  ul {
    margin: 0px;
  }
  .card {
    margin-top: 30px;
    height: 350px;
    img {
      width: 100%;
    }
  }
  .card.word-type {
    background: rgb(240, 240, 240);
    font-size: 20px;
    display: flex;
    justify-content: center;

    .word-block {
      padding: 25px;
    }
    .time {
      font-size: 18px;
      margin-bottom: 40px;
    }
    .feedback {
      line-height: 25px;
    }
    .name {
      text-align: end;
      font-size: 22px;
      margin-top: 24px;
    }
  }
}

@media (min-width: 800px) {
  .user-like-us {
    .scroll-block {
      height: 353px;
    }
    h1 {
      padding-top: 122px;
      font-size: 28px;
    }
    .scroll {
      margin-top: 80px;
    }
    .cases {
      margin: 11px 80px 50px 80px;
      display: flex;
      justify-content: center;
      gap: 3%;
      flex-wrap: wrap;

      .card {
        width: 31%;
        margin-top: 50px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
    .card.word-type {
      font-size: 16px;
      display: flex;
      align-items: center;
      .word-block {
        max-width: 290px;
        padding: 0px;
      }
      .name {
        margin-top: 50px;
        font-size: 18px;
        font-weight: bold;
      }
    }
  }
}
