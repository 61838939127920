.footer {
  // background-color: #555;
  background: url('./footer-background.png');
  position: relative;
  padding-bottom: 50px;

  .contact-info {
    max-width: 980px;
    margin: 0 auto;
    padding: 0px 0px 0px 20px;

    .header {
      display: flex;
      justify-content: space-between;

      > .title {
        font-size: 30px;
        padding-top: 36px;
        font-weight: 400;
        text-transform: uppercase;
      }

      .social-media {
        padding-top: 22px;

        img {
          width: 60px;
        }
      }
      @media only screen and (max-width: 768px) {
        display: block;
      }
    }
    .information {
      font-size: 16px;
      display: flex;
      flex-wrap: wrap;
      margin: 0px;

      > li {
        flex-grow: 1;
        padding: 34px 0px 10px 12px;

        .subtitle {
          margin-bottom: 25px;
          font-weight: 700;

          &:before {
            display: inline-block;
            content: ' ';
            width: 10px;
            height: 10px;
            background-color: black;
            margin-right: 6px;
            vertical-align: top;
            margin-top: 4px;
          }
        }

        > ul {
          margin-left: 10px;
        }

        // company information
        &.company-information {
          li {
            margin-top: 10px;
            > * {
              display: inline-block;
            }
            .label {
              margin-right: 10px;
            }
          }
        }

        // links
        &.links {
          padding-left: 0px;
          margin-left: -20px;
          ul {
            margin-top: 20px;
            li {
              display: flex;
              margin-top: 20px;
              font-weight: bold;
              > a {
                cursor: pointer;
                text-decoration: none;
                color: black;
              }
              > a:first-child {
                margin-right: 30px;
              }
            }
          }
        }

        // your information
        &.your-information {
          position: relative;
          padding-left: 0px;

          form {
            display: flex;
            flex-wrap: wrap;

            textarea {
              flex-grow: 1;
            }

            ul {
              margin-right: 10px;
            }

            li,
            textarea {
              margin-top: 10px;
            }

            li {
              input {
                width: 147px;
                height: 37px;
                text-indent: 15px;
                border: 2px solid rgb(145, 145, 145);
              }
            }
            textarea {
              text-indent: 15px;
              padding-top: 15px;
              height: 132px;
              resize: none;
              border: none;
              border: 2px solid rgb(145, 145, 145);
            }

            input.error,
            textarea.error {
              border: 1px solid red;
            }
          }

          button {
            position: absolute;
            background-color: rgb(50, 50, 50);
            width: 112px;
            height: 28px;
            color: white;
            border: none;
            right: 0px;
            bottom: 10px;
            font-size: 13px;
          }

          .social-media {
            display: none;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .footer {
    padding-bottom: 5px;
    .contact-info {
      padding-left: 0px;
      .header {
        .title {
          font-size: 25px;
          text-align: center;
        }
        .social-media {
          display: none;
        }
      }
      .information {
        font-size: 15px;

        > li.links {
          margin-left: 0px;
          padding: 24px 0px 6px 14px;

          ul li {
            flex-direction: column;
            margin: 0px;
            > a {
              text-decoration: none;
              color: black;
              margin-bottom: 20px;
              font-size: 15px;
            }
          }
        }
        > li.your-information {
          padding: 24px 14px 6px;

          form {
            ul {
              margin: 0px;
              width: 100%;
            }
            li {
              input {
                width: 100%;
              }
            }
            textarea {
              width: 100%;
            }
          }
          button {
            position: static;
            width: 120px;
            height: 42px;
            margin: 10px auto 0px;
          }
          .social-media {
            display: block;
            padding-top: 50px;
            display: flex;
            justify-content: center;
            gap: 10px;
            img {
              width: 42px;
            }
          }
        }
      }
    }
  }
}
